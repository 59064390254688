import { lazy } from "react";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Users = lazy(() => import("./pages/users/Users"));
const UserDetails = lazy(() => import("./pages/users/UserDetails"));
//ADD PRODUCT
const Products = lazy(() => import("./pages/product/Products"));
const AddProduct = lazy(() => import("./pages/product/AddProduct"));
const EditProduct = lazy(() => import("./pages/product/EditProduct"));
const ViewProduct = lazy(() => import("./pages/product/ViewProduct"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const OrderDetails = lazy(() => import("./pages/orders/OrderDetails"));
const MySlider = lazy(() => import("./pages/shopmanagement/MySlider"));
const ShopSetting = lazy(() => import("./pages/shopmanagement/ShopSetting"));
const Demands = lazy(() => import("./pages/demand/Demands"));
const DemandDetails = lazy(() => import("./pages/demand/DemandDetails"));
const Campaign = lazy(() => import("./pages/campaign/Campaign"));
const CampaignDetails = lazy(() => import("./pages/campaign/CampaignDetails"));
const MyCampaign = lazy(() => import("./pages/mycampaign/MyCampaign"));
const ViewCampaignDetails = lazy(() =>
  import("./pages/mycampaign/component/ViewCampaignDetails")
);
const MyOffers = lazy(() => import("./pages/myoffers/MyOffers"));
const ViewMyOfferDetails = lazy(() =>
  import("./pages/myoffers/component/ViewMyOfferDetails")
);
const DeliveryPartnerList = lazy(() =>
  import("./pages/deliverypartner/DeliveryPartnerList")
);
const Manage = lazy(() => import("./pages/manage/Manage"));
const CounterSale = lazy(() => import("./pages/countersale/CounterSale"));
const Wallet = lazy(() => import("./pages/wallet/Wallet"));
const Profile = lazy(() => import("./pages/shopmanagement/Profile"));
const Followers = lazy(() => import("./pages/myfollowers/Followers"));
const GstSetup = lazy(() => import("./pages/gstsetup/GstSetup"));
const SocialMediaLinks = lazy(() =>
  import("./pages/shopmanagement/SocialMediaLinks")
);
const BankDetails = lazy(() => import("./pages/shopmanagement/BankDetails"));
const SellingPlatformList = lazy(() =>
  import("./pages/sellingplatform/SellingPlatformList")
);
const ThemeAndLogo = lazy(() => import("./pages/shopmanagement/ThemeAndLogo"));
const Jobs = lazy(() => import("./pages/job/Jobs"));
const JobDetails = lazy(() => import("./pages/job/JobDetails"));
const ChangePassword = lazy(() =>
  import("./pages/changepassword/ChangePassword")
);
const UserGuide = lazy(() => import("./pages/userGuide/UserGuide"));
const ShopImageGallery = lazy(() =>
  import("./pages/shopmanagement/ShopImageGallery")
);

const routes = [
  { path: "/", exact: true, name: "Dashboard", element: Dashboard },
  { path: "/users", name: "Users", element: Users },
  {
    path: "/products",
    name: "Products",
    element: Products,
  },
  {
    path: "/product/:id",
    name: "Product Details",
    element: ViewProduct,
  },
  {
    path: "/add-product",
    name: "Add Product",
    element: AddProduct,
  },
  {
    path: "/edit-product/:id",
    name: "Edit Product",
    element: EditProduct,
  },
  //ORDERS ROUTES
  {
    path: "/orders",
    name: "Orders",
    element: Orders,
  },
  {
    path: "/order-details/:id",
    name: "Order Details",
    element: OrderDetails,
  },
  {
    path: "/my-shop-slider",
    name: "My Shop Slider",
    element: MySlider,
  },
  {
    path: "/my-shop-setting",
    name: "Shop Setting",
    element: ShopSetting,
  },
  {
    path: "/city-demand",
    name: "City Demand",
    element: Demands,
  },
  {
    path: "/demand-details",
    name: "Demand Details",
    element: DemandDetails,
  },
  //CAMPAIGN ROUTES
  {
    path: "/campaign",
    name: "Campaign",
    element: Campaign,
  },
  {
    path: "/campaign/:id",
    name: "Campaign Details",
    element: CampaignDetails,
  },
  //MY CAMPAIGN ROUTES
  {
    path: "/my-campaign",
    name: "My Campaign",
    element: MyCampaign,
  },
  {
    path: "/my-campaign-details/:id",
    name: "My Campaign Details",
    element: ViewCampaignDetails,
  },
  {
    path: "/my-offers",
    name: "My Offers",
    element: MyOffers,
  },
  {
    path: "/my-offer-details/:id",
    name: "My Offer Details",
    element: ViewMyOfferDetails,
  },
  {
    path: "/delivery-boy-list",
    name: "My Delivery Boy",
    element: DeliveryPartnerList,
  },
  {
    path: "/manage",
    name: "Manage",
    element: Manage,
  },
  {
    path: "/counter-sale",
    name: "Counter Sale",
    element: CounterSale,
  },
  {
    path: "/wallet",
    name: "Wallet",
    element: Wallet,
  },
  {
    path: "/shop-profile",
    name: "Shop Profile",
    element: Profile,
  },
  {
    path: "/my-followers",
    name: "My Followers",
    element: Followers,
  },
  {
    path: "/gst-settings",
    name: "GST Settings",
    element: GstSetup,
  },
  {
    path: "/social-media-links",
    name: "Social Media Links",
    element: SocialMediaLinks,
  },
  {
    path: "/bank-details",
    name: "Bank Details",
    element: BankDetails,
  },
  {
    path: "/selling-platform",
    name: "Selling Platforms",
    element: SellingPlatformList,
  },
  {
    path: "/theme-and-logo",
    name: "Theme and Logo",
    element: ThemeAndLogo,
  },
  {
    path: "/my-jobs",
    name: "My Jobs",
    element: Jobs,
  },
  {
    path: "/job-details/:id",
    name: "Job Details",
    element: JobDetails,
  },
  {
    path: "/change-password",
    name: "Change Password",
    element: ChangePassword,
  },
  {
    path: "/user-guide",
    name: "User Guide",
    element: UserGuide,
  },
  {
    path: "/shop-image-gallery",
    name: "Shop Image Gallery",
    element: ShopImageGallery,
  },
];

export default routes;
